.main-nav {
    margin-top: 0;
    padding: 3rem 0;
    width: 100%;

    .main-nav__wrapper {
        display: flex;
        align-items: flex-start;

        .site-list {
            flex-grow: 1;
            margin-right: auto;
        }

        .btn {
            font-size: 0.9rem;
            line-height: 1.063rem;
            width: 7.313rem;
            height: 2.563rem;
            padding-top: 0.813rem;
        }
    }

    .main-nav__list {
        list-style-type: none;
        display: flex;
        align-items: center;

        .site-list__item {
            padding: 0.188rem 0.781rem 0;
            align-self: center;
        }
    }

    .site-list__link {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.8rem;
        letter-spacing: 0.01em;
        color: rgba(52, 58, 82, 0.8);
        text-decoration: none;
        transition: color 0.2s linear;
    }
    &.main-nav-light {
        .site-list__link {
            color: rgba(255, 255, 255, 0.8);
        }
    }
    .user-list {
        .user-list__item {
            padding-top: 0;
            margin-top: -0.188rem;
        }

        .user-list__item:first-child {
            margin-right: 1.563rem;
        }

        .user-list__item:last-child {
            margin-left: 2.5rem;
        }

        .site-list__link {
            color: rgba(255, 255, 255, 0.8);
        }

        .dark .site-list__link {
            color: rgba(52, 58, 82, 0.8);
        }
    } 
}

.page-header {
    z-index: 10;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__logo {
        margin-right: 3.5rem;
    }
}

.logo-light {
    display: none;
}

.main-nav-light {
    .logo-light {
        display: block;
    }
    .logo-dark {
        display: none;
    }
}

#checkbox-menu,
.toggle {
    cursor: pointer;
    display: none;
}

.dropdown {
    .dropbtn {
        border: none;
        background-color: inherit;
        margin: 0;
        font-family: inherit;
        position: relative;
        padding-right: 0.75rem;
        padding-left: 0;

        &:after {
            content: "";
            width: 0.438rem;
            height: 0.25rem;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transition: transform 0.2s;
            transform: rotate(0deg);
            background-image: url("../images/content/arrow-down-dark.svg");
            background-repeat: no-repeat;
        }
        &.active {
            color: #34C3D3;
            &:after {
                background-image: url("../images/content/arrow-down.svg");
            }
        }
    }

    &:hover {
        .dropbtn:after {
            transform: rotate(-180deg);
        }

        .dropdown-content {
            display: block;
        }
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background: #343A52;
        border-radius: 0.938rem;
        padding: 1rem 1.5rem 1rem 1.375rem;
        z-index: 1;

        a {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.8rem;
            letter-spacing: 0.01em;
            color: #F7F7FF;
            padding: 0 0 0.313rem 0;
            text-decoration: none;
            display: block;

            &.active {
                color: #34C3D3;
            }
        }
    }
}

.main-nav a.site-list__link:hover,
.main-nav .active a.site-list__link,
.dropdown:hover .dropbtn,
.dropdown-content a:hover {
    cursor: pointer;
    color: #34c3d3;
}

@media screen and (max-width: 1080px) {
    .main-nav .user-list {
        .user-list__item:first-child {
            margin-right: 0.938rem;
        }

        .user-list__item:last-child {
            margin-left: 1.25rem;
        }
    }
}

@media screen and (max-width: 992px) {
    .main-nav .user-list .site-list__link {
        color: rgba(52, 58, 82, 0.8);
    }
}

@media screen and (max-width: 880px) {
    .main-nav {
        padding: 3.125rem 1.25rem;

        .main-nav__list .site-list__item {
            padding: 0.125rem 0.625rem 0;
        }

        .site-list__link {
            font-size: 0.875rem;
            line-height: 1.75rem;
        }

        .user-list .user-list__item:last-child {
            margin-left: 0.625rem;
        }
        
        .mobile-visible {
            display: none;
        }
    }

    .page-header__logo {
        margin-right: 1.25rem;

        img {
            width: 7.063rem;
            min-width: 7.063rem;
        }
    }
}

@media screen and (max-width: 760px) {
    .main-nav .main-nav__list .user-list, .main-nav .main-nav__list .user-list .user-list__item {
        display: none;
    }

    .main-nav .main-nav__wrapper .user-list, .main-nav .main-nav__wrapper .user-list .user-list__item,
    .main-nav .main-nav__wrapper .site-list, .main-nav .main-nav__wrapper .site-list .site-list__item {
        display: none;
    }

    .toggle {
        order: 2;
        clear: both;
        display: block;
        width: 1.313rem;
        height: 1.313rem;
        background-image: url("../images/content/toggle.svg");
        background-repeat: no-repeat;
        background-position: right top 0.25rem;
    }
    .main-nav-light .toggle {
        background-image: url("../images/content/toggle-light.svg");
    }

    .page-header {
        &.container {
            padding: 0;
        }

        &__logo {
            margin-right: 0;
        }
    }

    .main-nav, .main-nav__list {
        position: relative;
    }

    .main-nav {
        padding: 0;

        &__wrapper {
            position: relative;
            padding: 2.188rem 1.25rem;
            justify-content: space-between;
            margin-right: 0;
        }
    }

    .main-nav  .main-nav__list:not(.user-list) {
        margin-left: -0.125rem;
        margin-top: 0.938rem;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 0;
        transition: height 0.3s linear;
        order: 3;
        opacity: 0;
        display: none;
        width: 0;

        .site-list__item.nav-btn .site-list__link {
            color: #34C3D3;
        }

        li {
            display: flex;
            align-self: baseline;
        }

        .site-list__link {
            letter-spacing: normal;
            line-height: 1.8rem;
            font-size: 1.5rem;
            color: rgba(247, 247, 255, 0.9);
        }

        .dropdown {
            .dropbtn:after {
                width: 0.563rem;
                right: -0.313rem;
                background-size: contain;
                background-image: url("../images/content/arrow-down-light.svg");
            }
        }

        .dropdown-content {
            position: relative;
            background: transparent;

            a {
                padding: 0.469rem 0.313rem 0.469rem 1.313rem;
                padding-left: 1.313rem;
                color: rgba(247, 247, 255, 0.75);
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;

                &:hover {
                    background-color: transparent;
                    color: #34c3d3;
                }
            }

            a:first-child {
                padding-top: 1.25rem;
            }

            a:last-child {
                padding-bottom: 0;
            }
        }
    }

    #checkbox-menu:checked + label {
        align-items: flex-start;
        position: relative;

        &.main-nav__wrapper {
            background-color: #343a52;
            flex-wrap: wrap;
        }
        
        .site-list, .site-list .site-list__item {
            display: block;
        }

        .main-nav__list:not(.user-list) {
            transition: all 0.17 linear;
            opacity: 1;
            display: flex;
            height: 100vh;
            justify-content: flex-start;
            width: 100%;
        }

        .main-nav__list li {
            padding: 0.938rem 0;
            color: rgba(247, 247, 255, 0.9);
            margin-right: auto;
        }

        .logo-light {
            display: block;
        }

        .logo-dark {
            display: none;
        }
        
        .toggle {
            width: 1.313rem;
            height: 1.313rem;
            background-image: url("../images/content/close.svg");
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}
