.retro-page {
    .main-banner {

        &:after {
            display: none;
        }

        &__text {
            min-width: 36.25rem;
        }


        &__img {
            position: relative;
            min-width: 1157px;
            top: -0.5rem;
            margin: auto;

            img {
                margin-top: 0rem;
                width: 42.75rem;
            }

            &::before {
                content: "";
                background: url("../images/content/retro-banner-bg.svg");
                background-repeat: no-repeat;
                background-position: bottom left;
                width: 21.625rem;
                height: 21.875rem;
                display: block;
                z-index: -1;
                position: absolute;
                top: 17rem;
                left: -5.16rem;
            }
        }

        @media screen and (max-width: 1200px) {
            &__text {
                min-width: 36.1rem;
            }

            &__img::before {
                width: 14.188rem;
                height: 17.5rem;
                left: -4.563rem;
            }
        }

        @media screen and (max-width: 992px) {
            padding-bottom: 2.188rem;
            flex-direction: column;
            align-items: center;

            &__img {
                right: auto;
                min-width: auto;
                max-width: 42.375rem;
                padding-bottom: 1rem;//3.125rem;
                padding-left: 0;
                margin-right: auto;
                width: 100%;

                &::before,
                &::after {
                    display: none;
                }

                img {
                    transform: none;
                    width: 100%;
                    margin-top: 1.5rem;
                }
            }
        }

        @media screen and (max-width: 768px) {
            &__text {
                min-width: 30rem;
            }

        }

        @media screen and (max-width: 576px) {
            &__text {
                min-width: 100%;
            }

            &__img {
                margin-left: auto;
            }            
        }
    }

    .block-demo {
        background: #343a52;
        position: relative;
        border-bottom-left-radius: $border-radius;

        &::after {
            z-index: -1;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #ededfb;
        }

        &__img, &__video {
            box-shadow: 1.5rem 1.438rem rgba(52, 195, 211, 0.1);
        }

        &__text {
            width: auto;
            padding-top: 0;
            padding-right: 1.75rem;
            margin-right: 0;
            margin-top: 2.438rem;
            color: rgba(247, 247, 255, 0.6);
            max-width: none;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2.375rem;

            p:first-child {
                max-width: 26.375rem;
                margin-bottom: 2.063rem;
                margin-right: 0;
            }

            p:last-child {
                max-width: 29.125rem;
                margin-right: 0;
            }
        }

        @media screen and (max-width: 991px) {
            &__text {
                width: 50%;
                font-size: 1.25rem;
                line-height: 2.25rem;
                padding-right: 0;
                margin-top: 0;

                p:first-child {
                    margin-bottom: 0.938rem;
                }
            }
        }

        @media screen and (max-width: 768px) {
            border-bottom-left-radius: $border-radius-tablet;
        }

        @media screen and (max-width: 750px) {
            &__text {
                max-width: 100%;
                width: 100%;
                margin-bottom: 3.125rem;

                p:first-child,
                p:last-child {
                    max-width: none;
                }
            }

            &__img {
                margin-right: auto;
            }
        }

        @media screen and (max-width: 576px) {
            border-bottom-left-radius: $border-radius-xs;

            .block-demo {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .block-logos {
        background: #ededfb;
        border-bottom-right-radius: $border-radius;

        &::after {
            background: #343a52;
        }

        @media screen and (max-width: 768px) {
            border-bottom-right-radius: $border-radius-tablet;
        }

        @media screen and (max-width: 576px) {
            border-bottom-right-radius: $border-radius-xs;
        }
    }

    .slider-features {
        background: #F5F5FF;
        border-top-left-radius: $border-radius;

        &__navigation-short-item {
            color: #343a52;
        }

        &__navigation-item-content {
            color: rgba(52, 58, 81, 0.65);
        }

        &__navigation-item {
            padding: 0.5rem 1.563rem 0.563rem;
        }

        &__navigation-item.active {
            margin-bottom: 1.75rem;
            padding: 1.25rem 1.563rem 1.875rem;
        }

        &__navigation {
            flex: 0 0 29.375rem;
            padding-top: 1.625rem;
        }

        &::after {
            z-index: -1;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #ededfb;
        }
        
        @media screen and (max-width: 1100px) {
            &__navigation {
                flex: 0 0 26.875rem;
                padding-top: 0;
            }
        }
        
        @media screen and (max-width: 991px) {
            &__navigation {
                flex: 0 0 24.375rem;
            }
        }
        
        @media screen and (max-width: 768px) {
            border-top-left-radius: $border-radius-tablet;

            &__container {
                padding-bottom: 15.813rem;//22.813rem;
            }

            &__navigation {
                position: relative;
                flex-basis: auto;
                width: 100vw;
                margin-left: -1.25rem;
            }
            
            &__navigation-items {
                margin-top: 2.5rem;
            }
            
            &__navigation-item.active {
                margin-left: 1.5rem;
                margin-right: 2.188rem;
            }
        
            &__navigation-item:first-child {
                margin-left: 2.5rem;
            }
        
            &__navigation-item,
            &__navigation-item.active {
                padding: 0.813rem 0.813rem 1.188rem 1.563rem;
            }
        }

        @media screen and (max-width: 576px) {
            border-top-left-radius: $border-radius-xs;
            
            &__container {
                //padding-top: 5.938rem;
                padding-bottom: 16.813rem;//20.813rem;
            }

            &__navigation-short-item {
                line-height: 1.25rem;
                margin-bottom: 0.438rem;
            }
        
            &__navigation-item {
                flex: 0 0 15.938rem;
            }
        
            &__navigation-item-content {
                line-height: 1.188rem;
            }
        
            &__navigation-item:first-child.active {
                margin-left: 1.25rem;
            }
        
            &__navigation-item.active {
                margin-left: 0.25rem;
                margin-right: 0.938rem;
            }
        
            &__navigation-item,
            &__navigation-item.active {
                padding: 1.063rem 1.5rem 1.25rem 0.938rem;
            }
        
            &__navigation-item {
                padding-left: 1.563rem;
            }
        }
    }

    .middle-banner {
        background-image: url("../images/content/bg-geometric-light.jpg");

        &::after {
            z-index: -1;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #343a52;
        }

        &__text {
            font-family: Montserrat;
            color: #343a52;
            max-width: 53.438rem;
            margin: auto;
        }
    }

    .advantages-block {
        border-top-right-radius: $border-radius;
        padding-top: 2.75rem;//7.75rem;
        padding-bottom: 0.938rem;//5.938rem;
        position: relative;
        background: #343a52;

        &::after {
            z-index: -1;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: $border-radius;
            height: $border-radius;
            background: #bfc1d1;
        }

        &__title {
            font-family: Montserrat;
            font-weight: bold;
            font-size: 2.75rem;
            line-height: 4rem;
            text-align: center;
            color: rgba(247, 247, 255, 0.9);
            margin-bottom: 0.625rem;//2.625rem;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -0.938rem;
            margin-right: -0.938rem;
        }

        &__item {
            max-width: 25.375rem;
            margin: 1.063rem 0.938rem;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 1.875rem;
        }

        &__img {
            border-radius: 1.875rem;
            overflow: hidden;
        }

        &__text {
            padding: 1.563rem 1.563rem 2.125rem 1.563rem;
        }

        &__subtitle {
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 1.875rem;
            letter-spacing: 0.01em;
            color: rgba(247, 247, 255, 0.9);
            display: inline-block;
            margin-bottom: 0.625rem;
            margin-left: -0.5rem;
            padding: 0 0.5rem;
            border-radius: 0.313rem;
        }

        &__description {
            font-size: 1rem;
            line-height: 1.438rem;
            color: rgba(247, 247, 255, 0.65);
        }

        @media screen and (max-width: 768px) {
            border-top-right-radius: $border-radius-tablet;

            &__title {
                font-size: 1.875rem;
                line-height: 2.5rem;
            }
        }

        @media screen and (max-width: 576px) {
            border-top-right-radius: $border-radius-xs;

            &__subtitle {
                font-size: 1.125rem;
                line-height: 1.625rem;
            }

            &__text {
                font-size: 0.875rem;
                line-height: 1.313rem;
            }
        }
    }

    .bottom-banner {
        background: #343a52;
        padding-bottom: 3.125rem;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: $border-radius;
            height: $border-radius;
            background: #343a52;
            z-index: -1;
        }

        &__text {
            margin-right: 2.375rem;
        }

        &__container {
            justify-content: center;
        }

        @media screen and (max-width: 1320px) {
            &__text {
                margin-right: 0;
            }
        }
    }
}

.retro-beginners-page {
    .main-banner {
        position: relative;
        z-index: 2;
        
        &__img {
            top: 0;
            min-width: auto;
            //top: -7em;
            top: -9.1em;
            right: -1.25em;

            img {
                width: auto;
            }            
            &::before {
                display: none;
            }
        }
        &__subtitle {
            margin-top: 1.0315rem;
            margin-bottom: 1.5625rem;
        }
    }
    .benefits-block {
        margin-top: -12rem;
        padding-top: 13.438rem;
        padding-bottom: 10.125rem;
        background: url("../images/content/benefits-bg.png") no-repeat;
        background-size: cover;
        background-size: 100% 100%;

        &::before,
        &::after {
            display: none;
        }

        &__title {
            max-width: 41.313rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.938rem;
            font-size: 2.25rem;
            line-height: 150%;

        }

        svg {
            width: 4.375rem;
            height: 4.375rem;
            min-width: 4.375rem;
            min-height: 4.375rem;
            margin-right: 0px;
        }
/*         &__item {
            display: block;
            text-align: center;
        } 
        &__list {
            max-width: 53.75rem;
            margin-left: auto;
            margin-right: auto;
            
            grid-template-columns: 28% 25% 25%;
            grid-template-rows: auto auto;
            gap: 2.813rem 10%;
            grid-auto-flow: row;
            justify-content: center;
        }   
        &__item:nth-child(3), &__item:nth-child(4) {
            margin-left: 0;
        }    */          
        &__subtitle {
            max-width: 12.5rem;
            white-space: unset;
            font-size: 1.188rem;
            line-height: 132%;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0.01em;
            color: #343A52;
        }

        &__list2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

         }

        &__item2 {
            text-align: center;
            margin: 0 3.125rem 2.813rem;

            &:nth-child(2n) {
                margin: 0;
            }

            &:nth-child(6) {
                width: 100%;
            }

            &:nth-child(7), &:nth-child(9) {
                .benefits-block__subtitle {
                    max-width: 15.375rem;
                    margin-left: -1.4375rem;
                    margin-right: -1.4375rem;
                }
            }
            @media screen and (max-width: 1260px) {
                &:nth-child(6) {
                    width: auto;
                }
                &:nth-child(4), &:nth-child(10) {
                    width: 100%;
                }
            }  
            @media screen and (max-width: 960px) {
                &:nth-child(4), &:nth-child(10) {
                    width: auto;
                }
                &:nth-child(2), &:nth-child(6), &:nth-child(8), &:nth-child(12) {
                    width: 100%;
                }
            }              
      
        }

         
    }
    .question-block {
        padding-top: 1.25rem;
        margin-bottom: 5.313rem;

        .container {
            display: flex;
            justify-content: center;

        }
        &__img {
            min-width: 20.5rem;
            max-width: 31.25rem;
            margin-right: 3.938rem;
            align-self: center;
        }
        &__text {
            max-width: 28.188rem;
            align-self: center;
            font-weight: bold;
            font-size: 2.188rem;
            line-height: 157%;
            letter-spacing: 0.01em;
            color: rgba(52, 58, 82, 0.8);

            .text-violet {
                color: rgba(78, 87, 170, 0.8);
            }
        }

    } 
    .bottom-banner1 {
        position: relative;
        padding: 3.313rem 6.25rem 3.438rem;
        margin: 0 1.25rem -1.25rem;
        background: url("../images/content/bottom-banner1.png") no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        border-radius: 1.875rem;
        
        p {
            padding-bottom: 1.75rem;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #F7F7FF;
    
        }
        .btn {
            display: inline-block;
            height: auto;
            width: auto;
            padding: 0.938rem 2.063rem;
            line-height: 1.25rem;
        }
    }
    .three-benefist-block {
        padding-top: 8.125rem;
        padding-bottom: 7.875rem;
        background: #EDEDFB;

        &__title {
            max-width: 60.563rem;
            margin: 0 auto;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 2.75rem;
            line-height: 145%;
            text-align: center;
            color: #343A52;
        }
        &__list {
            display: flex;
            justify-content: space-between;
        }
        &__item {
            max-width: 25.688rem;
            align-self: top;
            text-align: center;

            &:first-child {
                max-width: 21.25rem;
            }
            &:last-child {
                max-width: 21.25rem;
            }

            img {
                height: 18.938rem;
                width: auto;
            }
        }
        &__subtitle {
            padding-bottom: 0.188rem;
            font-weight: bold;
            font-size: 1.375rem;
            line-height: 173%;
            letter-spacing: 0.01em;
            color: #343A52;
        }
        &__description {
            font-size: 1rem;
            line-height: 144%;
            color: rgba(52, 58, 82, 0.65);
        }
    }
    .bottom-banner2 {
        padding: 4.25rem 3.75rem 5.063rem;
        background: url("../images/content/bottom-banner2.png") no-repeat;
        background-size: cover;
        background-position: center;

        .container {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            text-align: left;
        }


        p {
            padding-right: 4.938rem;
            max-width: 60.75rem;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 1.688rem;
            line-height: 204%;
            color: #FFFFFF;
        }

        .btn {
            margin: auto;
            padding: 0.938rem 2.25rem;
            line-height: 1.25rem;
            align-self: center;
            white-space: nowrap;
        }

    }

}

@media screen and (max-width: 1280px) {
    .retro-beginners-page {
        .main-banner {
            &__img {
                right: 14%;
    
            }       
        }
        .benefits-block {
            padding-bottom: 9.125rem;
        }
    }
}


@media screen and (max-width: 1080px) {
    .retro-beginners-page {
        .main-banner {
            &__img {
                img {
                    width: 159%;
                    margin-top: 10%;
                }            
            }

        }   
        .three-benefist-block {
            &__list {
                flex-wrap: wrap;
                justify-content: space-evenly;
            }    
        }
        .bottom-banner2 {
    
            .container {
                flex-wrap: wrap;
                text-align: center;
            }
            p {
                max-width: none;
                padding-right: 0;
                padding-bottom: 1.875rem;
            }
    
        }    
        

    }
}


@media screen and (max-width: 992px) {
    .retro-beginners-page {

        .main-banner {
            &__img {
                right: auto;
                img {
                    width: 100%;
                }
            }   
            &__subtitle {
                margin: 1.563rem auto 1.813rem;
            }
            &__text {
                min-width: auto;
                padding-right: 0;
            }
            .container {
                height: auto;
            }
        }   
        .benefits-block {
            margin-top: -9rem;
            padding-top: 13.438rem;
            padding-bottom: 10.125rem;
        }    
    }
}

@media screen and (max-width: 768px) {
    .retro-beginners-page {
        .main-banner {
            &__img {
                height: 33.813rem;
                width: calc(100vw + 2rem);
                top: -5rem;
                left: -1.25rem;
                right: -1.25rem;
                background-image: url("../images/content/at-work.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                

                img {
                    display: none;
                }
            }       
        }
        .benefits-block {
            padding-top: 7.313rem;
            padding-bottom: 9.188rem;
            background-image: url("../images/content/benefits-bg-tablet.png");

            &__title {
                max-width: 30.25rem;
                margin-bottom: 3.063rem;
                font-size: 1.75rem;
            }
            &__list {
                grid-template-columns: 31% 25% 25%;
                grid-column-gap: 7%;
            }  
            &__subtitle {
                font-size: 1rem;
                line-height: 137%;
            }                   
    
        }
        .question-block {
            padding-top: 0.914rem;
            margin-bottom: 4.125rem;
            .container {
                flex-wrap: wrap;
            }

            &__img {
                max-width: 21.813rem;
                margin-right: 0;
            }
            &__text {
                width: 100%;
                font-size: 1.75rem;
                line-height: 175%;
            }
        }    
        .bottom-banner1 {
            padding: 2.688rem 5.5rem 3rem;
            background-image: url("../images/content/bottom-banner1-tablet.png");
            
            p {
                padding-bottom: 1.875rem;
                font-size: 1.875rem;
                line-height: 153%;
            }
        }
        .three-benefist-block {
            padding-top: 7.188rem;
            padding-bottom: 4.75rem;
    
            &__title {
                font-size: 1.875rem;
                line-height: 153%;
            }
            &__subtitle {
                padding-bottom: 0;
                font-size: 1.25rem;
                line-height: 190%;
            }
            &__description {
                line-height: 131%;
            }
        }        
        .bottom-banner2 {
            padding: 2.563rem 3.438rem 3.5rem;
    
            .container {
                text-align: center;
            }
            p {
                padding-right: 0;
                padding-bottom: 1.875rem;
                font-size: 1.563rem;
                line-height: 160%;
            }
    
        }    
    }
}

@media screen and (max-width: 576px) {
    .retro-beginners-page {
        .main-banner {
            &__img {
                top: -4rem;
                height: 31.813rem;
                width: calc(100vw + 1rem);
            }       
            &__subtitle {
                margin-top: 0.688rem;
                margin-bottom: 1.875rem;;
            }

        }   
        .benefits-block {
            margin-top: -6rem;
            padding-top: 7.188rem;
            padding-bottom: 6.438rem;
            background-image: url("../images/content/benefits-bg-mobile.png");

            &__title {
                max-width: 100%;
                margin-bottom: 2.625rem;
                font-size: 1.5rem;
            }
            &__list {
                grid-template-columns: 45% 47%;
                grid-column-gap: 8%;
            }  
            &__subtitle {
                line-height: 125%;
            }                   
    
        }
        .question-block {
            padding-top: 2.056rem;
            margin-bottom: 4.438rem;

            &__text {
                font-size: 1.5rem;
                line-height: 162%;
            }
        }            
        .bottom-banner1 {
            padding: 3.125rem 1.25rem 3.438rem;
            margin: 0;
            background-image: url("../images/content/bottom-banner1-mobile.png");
            border-radius: 0;
            
            p {
                padding-bottom: 1.813rem;
                line-height: 133%;
            }
        }
        .three-benefist-block {
            padding-top: 5.313rem;
            padding-bottom: 4.625rem;
    
            &__title {
                margin: 0 1.25rem;
                line-height: 133%;
            }
        }        
        .bottom-banner2 {
            padding: 2.563rem 1.25rem 2.875rem;

            p {
                padding-bottom: 2.125rem;
                font-size: 1.375rem;
                line-height: 155%;
            }
    
        }    

    }
}
