.pricing {
    padding-bottom: 5.313rem;
    position: relative;
    height: 100%;

    .container {
        margin-top: -0.625rem;
        background-image: url("../images/content/pricing-left.svg"), url("../images/content/pricing-right.svg");
        background-position: left 0 bottom 0.938rem, right 3.375rem bottom 1.875rem;
        background-repeat: no-repeat, no-repeat;
        padding-bottom: 5rem;
        max-width: 1440px;
    }

    &__title {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 160%;
        text-align: center;
        color: #343a52;
//        padding-bottom: 2.438rem;
    }

    &__additional-info {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 161%;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgba(52, 58, 82, 0.7);

    }

    &__vat-info {
        font-size: 1rem;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgba(52, 58, 82, 0.7);
    }

    &__plans {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__plan {
        max-width: 21.688rem;
        width: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top right;
        border-radius: 1.625rem;
        margin: 0 1.25rem 1.25rem;
    }

    .plan {
        &__header,
        &__body {
            padding-left: 3.125rem;
            padding-right: 2.75rem;
        }

        &__header {
            padding-top: 1rem;
            padding-bottom: 0.875rem;
        }

        .plan__year {
            display: none;
        }

        &__title {
            font-weight: bold;
            font-size: 1.75rem;
            line-height: 2.125rem;
            text-shadow: 1px 1px 0 #34c3d3;
            padding-bottom: 1.375rem;
        }

        &__body {
            height: 25.25rem;
            display: flex;
            flex-direction: column;
            padding-bottom: 3.3rem;
            padding-top: 1.125rem;
        }

        &__list li {
            background: url("../images/content/list-green-check-pricing.svg") no-repeat 0 0; /* Параметры фона */
            padding-left: 1.938rem;
            padding-bottom: 0.813rem;
            font-weight: normal;
            font-size: 0.938rem;
            line-height: 1.125rem;
            color: rgba(255, 255, 243, 0.7);
            ul {
                margin-top: 0.625rem;
                li {
                    padding-left: 1.188rem;
                    background-image: url("../images/content/list-check-pricing.svg");
                    background-position: 0 0.25rem;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .btn {
            margin-top: auto;
            width: 100%;
        }
    }

    .plan-dark {
        background: url("../images/content/pricing-bg-dark.jpg");

        .plan {
            &__header {
                background: #34475f;
                font-weight: normal;
                font-size: 1.063rem;
                line-height: 1.313rem;
                color: rgba(255, 255, 255, 0.6);
            }

            &__sum {
                text-shadow: 1px 1px 0 #34c3d3;
                color: #fff;
                font-weight: 600;
                font-size: 2.25rem;
                line-height: 2.875rem;
                margin-right: 0.313rem;
            }

            &__title {
                color: #ffffff;
            }

            &__list li {
                background-image: url("../images/content/list-green-check-pricing-dark.svg");
                color: rgba(255, 255, 243, 0.7);
                li {
                    background-image: url("../images/content/list-check-pricing-dark.svg");
                }

            }
        }
    }

    .plan-light {
        background: url("../images/content/pricing-bg-light.jpg");

        .plan {
            &__header {
                background: rgba(78, 87, 170, 0.2);
                font-weight: normal;
                font-size: 1.063rem;
                line-height: 1.313rem;
                color: rgba(52, 58, 82, 0.6);
            }

            &__sum {
                text-shadow: 1px 1px 0 #34c3d3;
                color: #343A52;
                font-weight: 600;
                font-size: 2.25rem;
                line-height: 2.875rem;
                margin-right: 0.313rem;
            }

            &__title,
            &__list li {
                color: #343A52;
            }

        }
    }

    .switch {
        display: block;
        margin: 0.625rem;
        text-align: center;
  
        input[type="checkbox"] {
            display: none;
  
            &:checked + label {
                span:first-child {
                    color: rgba(52, 58, 82, 0.8);
                }
                span:last-child {
                    color:  #FFFFFF;
                }
            }

            &:checked + label:after {
                left: 50%;
            }
        }


        label {
            position: relative;
            display: inline-block;
            height: 2.813rem;
            width: 14.063rem;
            background-color: #E8E8F7;
            border-radius: 6.25rem;
            cursor: pointer;
            color: #FFFFFF;
            user-select: none;
            transition: all 200ms ease-in-out;

            span {
                transition: all 200ms ease-in-out;
                position: relative;
                z-index: 5;
                display: inline-block;
                width: 50%;
                font-weight: 600;
                font-size: 1rem;
                line-height: 2.813rem;
                text-align: center;
                letter-spacing: 0.01em;
            }

            span:first-child {
                padding-left: 0.5rem;
                color:  #FFFFFF;
            }
            span:last-child {
                padding-right: 0.5rem;
                color: rgba(52, 58, 82, 0.8);
            }


            &:after {
                content: ' ';
                position: absolute;
                top: 0.313rem;
                left: 0.5rem;
                right: auto;
                height: 2.188rem;
                width: 6.813rem;
                background-color: #34C3D3;
                border-radius: 6.25rem;
                transition: all 200ms ease-in-out;
    
            }
        }


        

    }

    @media screen and (max-width: 1370px) {
        padding-bottom: 9.25rem;

        .container {
            background-image: none;
            padding-bottom: 0;
        }
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 6rem;
        padding-top: 0.438rem;
    
        &__title {
            font-size: 1.875rem;
            line-height: 3.375rem;
            padding-bottom: 1.563rem;
            letter-spacing: 0.02em;
        }

        .pricing__plan {
            min-width: 20.75rem;
            width: 20.75rem;
            margin: 0 0.75rem;
        }

        .plan__header, .plan__body {
            padding-left: 2.563rem;
            padding-right: 2.563rem;
        }
    }

    @media screen and (max-width: 720px) {
        margin-top: 0;
        padding-bottom: 4.125rem;

        .pricing__plans {
            flex-direction: column;
        }

        .pricing__plan {
            margin: 0 auto 1.875rem;
        }
    }

    @media screen and (max-width: 576px) {
        padding-bottom: 0.625rem;
    
        &__title {
            line-height: 2.688rem;
            padding: 0 0.625rem 2.125rem 0.625rem;
        }

        .plan__header, .plan__body {
            padding-left: 2.438rem;
        }

        .plan__title {
            padding-bottom: 1.563rem;
        }
    }
}
