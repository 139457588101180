.industries-page {
    &.internet .main-title {
        color: #ededfb;

        .text-marker-red {
            &::before {
                z-index: -1;
                top: auto;
                bottom: -25px;
                left: -10px;
                width: 211px;
                height: 44px;
                background: url("../images/content/text-marker-underline.svg");
            }
        }
    }

    .main-title {
        max-width: 441px;
        font-size: 39px;
        line-height: 50px;

        .text-marker-red {
            position: relative;
            font-family: "Montserrat", sans-serif;
            display: inline-block;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -19px;
                left: -10px;
                width: 152px;
                height: 83px;
                background: url("../images/content/text-marker-red.svg");
                background-repeat: no-repeat;
                background-position: bottom left;
            }
        }
    }

    &.internet .main-banner {
        &__subtitle {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.01em;
            color: rgba(237, 237, 251, 0.8);
        }

        &::before,
        &::after {
            display: none;
        }

        &__img {
            top: -84px;
        }

        @media screen and (max-width: 1200px) {
            &__img {
                margin-top: 30px;
                top: 0;
            }
        }
    }

    .main-banner {
        position: relative;
        z-index: 2;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: $border-radius;
            height: $border-radius;
            z-index: 3;
        }

        &::before {
            background-image: url(../images/content/telecom-banner-bg.svg);
            background-position: left top;
            border-bottom-right-radius: $border-radius;
            background-position: left 3px top 2px;
            z-index: 4;
        }

        @media screen and (max-width: 768px) {
            &::before,
            &::after {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
            }

            &::before {
                border-bottom-right-radius: $border-radius-tablet;
            }
        }

        @media screen and (max-width: 576px) {
            &::before,
            &::after {
                width: $border-radius-xs;
                height: $border-radius-xs;
            }

            &::before {
                border-bottom-right-radius: $border-radius-xs;
            }
        }

        &::after {
            background: #ededfb;
            z-index: 3;
        }

        &__text {
            min-width: 36.25rem;
        }

        &__subtitle {
            margin-top: 1.0315rem;
            margin-bottom: 1.5625rem;
            max-width: 430px;
        }

        &__img {
            position: relative;
            right: 0;
            top: 0;
            margin: auto;

            img {
                margin-top: 0rem;
            }
        }

        @media screen and (max-width: 1200px) {
            &__text {
                min-width: 27.1rem;
            }

            &__img::before {
                width: 14.188rem;
                height: 17.5rem;
                left: -4.563rem;
            }
        }

        @media screen and (max-width: 992px) {
            padding-bottom: 2.188rem;
            flex-direction: column;
            align-items: center;

            &__img {
                right: auto;
                min-width: auto;
                max-width: 42.375rem;
                padding-bottom: 1rem;
                padding-left: 0;
                margin-right: auto;
                width: 100%;

                &::before,
                &::after {
                    display: none;
                }

                img {
                    transform: none;
                    width: 100%;
                    margin-top: 1.5rem;
                }
            }
        }

        @media screen and (max-width: 768px) {
            &__text {
                min-width: 30rem;
            }
        }

        @media screen and (max-width: 576px) {
            &__text {
                min-width: 100%;
            }

            &__img {
                margin-left: auto;
            }
        }
    }

    .block-demo {
        background: #343a52;
        position: relative;
        border-bottom-left-radius: $border-radius;

        &::after {
            z-index: -1;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #ededfb;
        }

        &__img,
        &__video {
            box-shadow: 1.5rem 1.438rem rgba(52, 195, 211, 0.1);
        }

        &__text {
            width: auto;
            padding-top: 0;
            padding-right: 1.75rem;
            margin-right: 0;
            margin-top: 2.438rem;
            color: rgba(247, 247, 255, 0.6);
            max-width: none;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2.375rem;

            p:first-child {
                max-width: 26.375rem;
                margin-bottom: 2.063rem;
                margin-right: 0;
            }

            p:last-child {
                max-width: 29.125rem;
                margin-right: 0;
            }
        }

        @media screen and (max-width: 991px) {
            &__text {
                width: 50%;
                font-size: 1.25rem;
                line-height: 2.25rem;
                padding-right: 0;
                margin-top: 0;

                p:first-child {
                    margin-bottom: 0.938rem;
                }
            }
        }

        @media screen and (max-width: 768px) {
            border-bottom-left-radius: $border-radius-tablet;

            &::after {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
            }
        }

        @media screen and (max-width: 750px) {
            &__text {
                max-width: 100%;
                width: 100%;
                margin-bottom: 3.125rem;

                p:first-child,
                p:last-child {
                    max-width: none;
                }
            }

            &__img {
                margin-right: auto;
            }
        }

        @media screen and (max-width: 576px) {
            border-bottom-left-radius: $border-radius-xs;

            &::after {
                width: $border-radius-xs;
                height: $border-radius-xs;
            }

            .block-demo {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .block-logos {
        background: #fff;
        border-radius: 0;
    }

    .bottom-banner {
        background: #343a52;
        padding-bottom: 3.125rem;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: $border-radius;
            height: $border-radius;
            background: #343a52;
            z-index: -1;

            @media screen and (max-width: 768px) {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
            }

            @media screen and (max-width: 576px) {
                width: $border-radius-xs;
                height: $border-radius-xs;
            }
        }

        &__text {
            margin-right: 2.375rem;
        }

        &__container {
            justify-content: center;
        }

        @media screen and (max-width: 1320px) {
            &__text {
                margin-right: 0;
            }
        }
    }

    .benefits-block {
        padding-top: 93px;
        padding-bottom: 113px;
        background-color: #fff;

        &::before,
        &::after {
            display: none;
        }

        &__title {
            max-width: 41.313rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.938rem;
            font-size: 2.25rem;
            line-height: 150%;
        }

        svg {
            width: 4.375rem;
            height: 4.375rem;
            min-width: 4.375rem;
            min-height: 4.375rem;
            margin-right: 0px;
        }
 
        &__list2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

         }

        &__item2 {
            text-align: center;
            margin: 0 3.125rem 2.813rem;

            &:nth-child(2n) {
                margin: 0;
            }

            &:nth-child(6) {
                width: 100%;
            }

            &:nth-child(7), &:nth-child(9) {
                .benefits-block__subtitle {
                    max-width: 15.375rem;
                    margin-left: -1.4375rem;
                    margin-right: -1.4375rem;
                }
            }
            @media screen and (max-width: 1260px) {
                &:nth-child(6) {
                    width: auto;
                }
                &:nth-child(4), &:nth-child(10) {
                    width: 100%;
                }
            }  
            @media screen and (max-width: 960px) {
                &:nth-child(4), &:nth-child(10) {
                    width: auto;
                }
                &:nth-child(2), &:nth-child(6), &:nth-child(8), &:nth-child(12) {
                    width: 100%;
                }
            }              
      
        }

 /*        &__item {
            display: block;
            text-align: center;
        }
        &__list {
            max-width: 53.75rem;
            margin-left: auto;
            margin-right: auto;
            grid-template-columns: 28% 25% 25%;
            grid-template-rows: auto auto; 
            gap: 2.813rem 10%;
            grid-auto-flow: row;
            justify-content: center;
        }
        &__item:nth-child(3),
        &__item:nth-child(4) {
            margin-left: 0;
        } */
        &__subtitle {
            max-width: 12.5rem;
            white-space: unset;
            font-size: 1.188rem;
            line-height: 132%;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0.01em;
            color: #343a52;

        }
    }

    &.internet .bottom-banner1 {
        background-image: url("../images/content/bottom-banner-internet.png");

        &__title {
            max-width: 879px;
        }
    }

    .bottom-banner1 {
        position: relative;
        padding: 3.313rem 6.25rem 3.438rem;
        margin: 0 1.25rem 0;
        background-image: url("../images/content/bottom-banner1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        border-radius: 1.875rem;

        &__title {
            max-width: 781px;
            margin: 0 auto 15px;
            font-family: Montserrat;
            font-weight: bold;
            font-size: 30px;
            line-height: 43px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #f7f7ff;
        }

        &__subtitle {
            max-width: 554px;
            margin: 0 auto 42px;
            font-weight: normal;
            font-size: 22px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgba(247, 247, 255, 0.8);
        }

        .btn {
            display: inline-block;
            height: auto;
            width: auto;
            padding: 0.938rem 2.063rem;
            line-height: 1.25rem;
        }
    }

    &.internet .bottom-banner2 {
        .container {
            background-image: url("../images/content/internet-banner1.svg");
        }
    }

    .bottom-banner2 {
        background-color: #343a52;

        .container {
            padding: 66px 60px 83px;
            background-image: url("../images/content/telecom-banner1.svg");
            background-repeat: no-repeat;
            background-position: bottom 0 right 62px;
        }

        .text-marker {
            background: rgba(255, 255, 255, 0.1);
        }

        p {
            margin-bottom: 35px;
            max-width: 732px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 54px;
            letter-spacing: 0.02em;
            color: #f7f7ff;
        }

        .btn {
            padding: 0.938rem 2.25rem;
            line-height: 1.25rem;
            align-self: center;
            white-space: nowrap;
        }
    }

    &.internet .how-works-block {
        &__title-block {
            max-width: 1045px;
        }
    }

    .how-works-block {
        padding-top: 74px;
        padding-bottom: 84px;
        background: #ededfb;
        border-top-left-radius: $border-radius;

        &__title-block {
            font-family: Montserrat;
            max-width: 796px;
            margin: 0 auto 35px;
            font-weight: bold;
            font-size: 30px;
            line-height: 48px;
            text-align: center;
            color: #343a52;
        }

        &__list {
            max-width: 1063px;
            margin: auto;
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__text {
            text-align: left;
        }

        &__item:nth-child(1) {
            .how-works-block__img {
                margin-right: 53px;
            }

            .how-works-block__subtitle {
                max-width: 352px;
            }
        }

        &__item:nth-child(2) {
            .how-works-block__text {
                margin-right: 50px;
            }

            .how-works-block__subtitle {
                max-width: 367px;
            }
        }

        &__item:nth-child(3) {
            .how-works-block__img {
                margin-right: 55px;
            }

            .how-works-block__subtitle {
                max-width: 390px;
            }
        }

        &__item:nth-child(4) {
            .how-works-block__text {
                margin-right: 32px;
            }

            .how-works-block__subtitle {
                max-width: 375px;
            }
        }

        &__item:nth-child(5) {
            .how-works-block__img {
                margin-right: 55px;
            }

            .how-works-block__subtitle {
                max-width: 404px;
            }
        }

        &__item:nth-child(6) {
            .how-works-block__text {
                margin-right: 44px;
            }

            .how-works-block__text {
                max-width: 358px;
            }
        }

        &__title {
            font-weight: bold;
            text-align: left;
            margin-bottom: 10px;
            font-size: 26px;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #343a52;
        }

        &__subtitle {
            min-width: 300px;
            text-align: left;
            margin-bottom: 0;
            font-size: 20px;
            line-height: 27px;
            color: rgba(52, 58, 82, 0.65);
        }

        &__img {
            max-width: 558px;
        }

        &__item:nth-child(2n) {
            flex-direction: row-reverse;
        }

        @media screen and (max-width: 768px) {
            border-top-left-radius: $border-radius-tablet;
        }

        @media screen and (max-width: 576px) {
            border-top-left-radius: $border-radius-xs;
        }

        @media screen and (max-width: 750px) {
            padding-bottom: 20px;

            &__item,
            &__item:nth-child(2n) {
                flex-direction: column-reverse;
            }

            &__item:nth-child(1) .how-works-block__img,
            &__item:nth-child(2) .how-works-block__text,
            &__item:nth-child(3) .how-works-block__img,
            &__item:nth-child(4) .how-works-block__text,
            &__item:nth-child(5) .how-works-block__img,
            &__item:nth-child(6) .how-works-block__text,
            &__img {
                margin-right: 0;
            }

            &__img {
                margin-top: 30px;
                margin-bottom: 60px;
                max-width: 458px;
            }

            &__item:nth-child(6) .how-works-block__text,
            &__item .how-works-block__text .how-works-block__subtitle {
                max-width: none;
                min-width: auto;
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .industries-page.internet,
    .industries-page {
        .benefits-block {
            padding-bottom: 9.125rem;
        }
    }
}

@media screen and (max-width: 1200px) {
    .industries-page.internet,
    .industries-page {
        .benefits-block {
            padding-bottom: 9.125rem;
        }
    }
}

@media screen and (max-width: 1165px) {
    .industries-page.internet,
    .industries-page {
        .bottom-banner2 {
            .container {
                padding-bottom: 150px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .industries-page.internet,
    .industries-page {
        .benefits-block {
            padding-top: 13.438rem;
            padding-bottom: 10.125rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .industries-page.internet,
    .industries-page {
        .main-title {
            max-width: none;
            font-size: 2.25rem;
            line-height: 3.375rem;
            text-align: center;
        }
        .how-works-block__title-block {
            line-height: 150%;
            font-size: 1.75rem;
        }
        .how-works-block__title {
            line-height: 150%;
            font-size: 1.5rem;
        }

        .benefits-block {
            padding-top: 7.313rem;
            padding-bottom: 9.188rem;

            &__title {
                max-width: 30.25rem;
                margin-bottom: 3.063rem;
                font-size: 1.75rem;
            }
            &__list {
                grid-template-columns: 31% 25% 25%;
                grid-column-gap: 7%;
            }
            &__subtitle {
                font-size: 1rem;
                line-height: 137%;
            }
        }
        .bottom-banner1 {
            padding: 2.688rem 5.5rem 3rem;
            background-image: url("../images/content/bottom-banner1-tablet.png");

            &__title {
                padding-bottom: 1.875rem;
                font-size: 1.875rem;
                line-height: 153%;
            }
        }
        .bottom-banner2 {
            padding: 2.563rem 3.438rem 3.5rem;

            .container {
                padding-bottom: 0;
                text-align: center;
                background-image: none;
            }
            p {
                padding-right: 0;
                padding-bottom: 1.875rem;
                font-size: 1.563rem;
                line-height: 160%;
            }
            .btn {
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .industries-page .main-title {
        .text-marker-red::before {
            width: 116px;
            height: 63px;
            background-size: cover;
            top: -11px;
            left: -8px;
        }
    }
    .industries-page.internet .main-title {
        .text-marker-red::before {
            width: 157px;
            height: 33px;
            background-size: cover;
            top: 28px;
            left: -8px;
        }
    }
    .industries-page.internet,
    .industries-page {
        .main-banner__subtitle {
            max-width: none;
        }
        .main-title {
            font-size: 1.875rem;
            line-height: 2.875rem;
            text-align: left;
            max-width: none;
        }
        .how-works-block__title-block {
            font-size: 1.5rem;
        }
        .how-works-block__title {
            font-size: 1.35rem;
        }

        .benefits-block {
            padding-top: 7.188rem;
            padding-bottom: 6.438rem;

            &__title {
                max-width: 100%;
                margin-bottom: 2.625rem;
                font-size: 1.5rem;
            }
            &__list {
                grid-template-columns: 45% 47%;
                grid-column-gap: 8%;
            }
            &__subtitle {
                line-height: 125%;
            }
        }
        .bottom-banner1 {
            padding: 3.125rem 1.25rem 3.438rem;
            margin: 0;
            background-image: url("../images/content/bottom-banner1-mobile.png");
            border-radius: 0;

            &__title {
                padding-bottom: 1.813rem;
                line-height: 133%;
            }
        }
        .bottom-banner2 {
            padding: 2.563rem 1.25rem 2.875rem;

            .container {
                padding: 0;
            }

            p {
                padding-bottom: 0;
                font-size: 1.375rem;
                line-height: 155%;
            }
        }
    }
}
