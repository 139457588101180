// Colors
$white: #fff;
$black: #000;

// Sizes
$base-font-size: 16px;

$tablet: 768;
$large: 1024;
$desktop: 1280;

$content: 1320px;
$border-radius: 100px;
$border-radius-tablet: 50px;
$border-radius-xs: 20px;

// Fonts
