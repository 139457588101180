.docs-page {
    background: #F7F7FF;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../images/content/docs-bg.jpg");

    .content {
        background: none;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #F7F7FF;
            z-index: 0;
            border-bottom-left-radius: $border-radius;
        }
    
        &::before {
            z-index: 0;
        }
    }

    @media screen and (max-width: 576px) {
        background-color: #fff;
    }
}

.docs {
    margin: 0 auto;
    max-width: 72.5rem;

    .docs-title {
        max-width: 82.5rem;
        margin-bottom: 1.25rem;
        font-size: 1.75rem;
        line-height: 2.125rem;
        font-weight: bold;
        color: #222831;

        span {
            margin-left: 1.875rem;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.875rem;
            color: rgba(52, 58, 82, 0.5);
        }
    }
    
    .container {
        position: relative;
        width: auto;
        padding: 1.875em 2.5rem 0;
        margin: 0 2.5rem 5.563rem;
        background: #FFFFFF;
        box-shadow: 0px 0.125px 0.931px rgba(0, 0, 0, 0.07);
        border-radius: 1.875rem;
        z-index: 10;
        
        img {
            margin-bottom: 2.688rem;
            border-radius: 0.625rem;
        }
        .docs-text {
            font-size: 1.125rem;
            line-height: 1.875rem;
            color: #343A52;
            padding: 0 5rem  1.563rem;
            
            h2 {
                padding: 1.25rem 0 0.938rem;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.5rem;
                color: #222831;
            }

            p {
                margin-bottom: 0.625rem;
                text-align: justify;
            }

            ul {
                list-style: disc;
            }

            ol {
                list-style: decimal;
            }

            ul,ol {
                padding: 0 1.25rem  0;
                list-style-position: outside;
                margin-left: 0;
            }

            a {
                color: #34C3D3;
                transition: color 0.2s linear;
                
                &:hover {
                    color: #ade8ef;
                }
            }            
        }
    }

    @media screen and (max-width: 450px) {
        .docs-title span {
            display: block;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .docs-page {
        background-size: auto;

        .content {
            &::before {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
            }
                
            &::after {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
                border-bottom-left-radius: $border-radius-tablet;
            }
        }
    }

    .docs {
        .docs-title {
            h1 {
                padding: 0 2.5rem 1.813rem;
                font-size: 1.75rem;
                line-height: 2.813rem;
            }
        }
        
        .container {
            width: auto;
            margin: 0 2.5rem 4.75rem;
            padding: 1.875em 1.563rem 0;
            
            .docs-text {
                font-size: 1rem;
                line-height: 1.625rem;
                padding: 0 0 1.063rem;
                
                h2 {
                    font-size: 1.5rem;
                    line-height: 1.813rem;
                }
    
                img {
                    margin-bottom: 2.188rem;
                }
            }
        }  
    }

}

@media screen and (max-width: 576px) {
    .docs-page {
        .content {
            &::after {
                display: none;
            }
        }
    }

    .docs {
        .docs-title {
            h1 {
                padding: 0 1.25rem 1.875rem;
                font-size: 1.5rem;
                line-height: 2.313rem;
                text-align: left;
            }
        }
        
        .container {
            margin: 0;
            padding: 1.563rem 0 0;

            .docs-text {
                line-height: 1.438rem;
                padding: 0 1.25rem 1.875rem;
                
                h2 {
                    font-size: 1.375rem;
                    line-height: 1.688rem;
                }
    
                img {
                    margin-bottom: 1.875rem;
                }
            }
            
        }
    }
}
