.blog {
    .blog_title {
        display: flex;
        height: 11.875rem;
        margin-bottom: 1.5rem;
        background:  url("../images/content/blog-title.png") center center no-repeat;
        background-size: cover;
        border-radius: 1.1rem;
        justify-content: center;

        h1 {
            margin: auto 3.875rem;
            font-family: Montserrat;
            font-size: 2.25rem;
            line-height: 4rem;
            letter-spacing: 0.02em;
            text-align: center;
            vertical-align: middle;
            font-weight: bold;
            color: #F7F7FF;

            span {
                padding: 0 0.3rem;
                background: rgba(52, 195, 211, 0.3);
                border-radius: 5px;
                white-space: nowrap;
            }
        }
    }
    .blogs-block {
        border-top-right-radius: $border-radius;
        padding-top: 1rem;
        padding-bottom: 5.938rem;
        position: relative;


        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -0.9rem;
        }
        .block__item-left {
            margin: 0.969rem 0.9rem;
            background: #f7f7ff;
            width: 25.375rem;
        }

        &__item {
            max-width: 25.375rem;
            margin: 0.969rem 0.9rem;
            background: #EDEDF7;
            border-radius: 1.875rem;

            a .blogs-block__subtitle {
                transition: color 0.2s linear;
            }

            a:hover .blogs-block__subtitle {
                color: #34c3d3;
            }

        }

        &__img {
            position: relative;
            height: 13.75rem;
            overflow: hidden;
            border-radius: 1.875rem 1.875rem 0 0;

            img {
                display: block;
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &__text {
            padding: 1.25rem 1.563rem 2.188rem 1.563rem;
            font-size: 0.875rem;
            line-height: 1.375rem;
            letter-spacing: 0.01em;
            color: rgba(52, 58, 82, 0.7);
        }

        &__subtitle {
            font-family: Proxima Nova;
            font-size: 1.125rem;
            line-height: 1.575rem;
            letter-spacing: 0.01em;
            font-weight: bold;
            color: #343A52;
            display: inline-block;
            margin-bottom: 0.625rem;
            border-radius: 0.313rem;

        }


    }    
    @media screen and (max-width: 768px) {

        .blog_title {
            height: 11.25rem;
            margin-bottom: 1rem;
            background-image:  url("../images/content/blog-title-tablet.png");

            h1 {
                font-size: 1.875rem;
                line-height: 2.938rem;
            }
        }
        .blogs-block {
            border-top-right-radius: $border-radius-tablet;
            padding-top: 0.875rem;

            &__item {
                max-width: 20.875rem;
                margin: 0.625rem 0.625rem;

            }
            
            .block__item-left {
                width: 20.875rem;
            }

            &__text {
                padding: 1rem 0.938rem 1.563rem 0.938rem;
            }

            &__subtitle {
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }
    }
    @media screen and (max-width: 576px) {
        .container {
            padding: 0;
        }

        .blog_title {
            height: 12.5rem;
            background-image:  url("../images/content/blog-title-xs.png");
            border-radius: 0;

            h1 {
                line-height: 2.625rem;
            }

        }
        .blogs-block {
            border-top-right-radius: $border-radius-xs;
            padding-top: 0.563rem;

            &__list {
                display: block;
                margin: 0;
            }
    
            &__item {
                max-width: 100%;
                margin: 1.25rem;
                line-height: 1.313rem;
            }

            &__text {
                padding-bottom: 1.438rem;
            }

            &__subtitle {
                line-height: 1.3rem;
            }
        }
    }

}

.article-page {
    background: #F7F7FF;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;

    .content {
        background: none;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: $border-radius;
            height: $border-radius;
            background: #F7F7FF;
            z-index: 0;
            border-bottom-left-radius: $border-radius;
        }
    
        &::before {
            z-index: 0;
        }
    }
}
.solutions-online-retrospective-top-bg {
    background-image: url("../images/content/blog/5-solutions-to-boost-team-engagement-during-your-online-retrospective.jpg");
}

.article {
    margin: 0 auto;
    max-width: 72.5rem;

    .article-title {
        max-width: 82.5rem;
        margin: 0 auto;

        h1 {
            padding:2.875rem 2.5rem 3.125rem;
            font-family: Montserrat;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 3.254rem;
            text-align: center;
            color: #FFFFFF;
        }
    }
    
    .container {
        position: relative;
        width: auto;
        padding: 1.875em 2.5rem 0;
        margin: 0 2.5rem 5.563rem;
        background: #FFFFFF;
        box-shadow: 0px 0.125px 0.931px rgba(0, 0, 0, 0.07);
        border-radius: 1.875rem;
        z-index: 10;
        
        .article-info {
            overflow: hidden;
            font-family: Proxima Nova;
            font-size: 1rem;
            line-height: 1.25rem;
            margin-bottom: 1.438rem;

            &__author {
                padding-right: 0.625rem;
                color: #343A52;

                img {
                    display: inline-block;
                    width: 1.875rem;
                    margin-right: 0.5rem;
                    border-radius: 1.875rem;
                }
            }

            &__date {
                color: rgba(52,58,82, 0.5);
            }
            .article-info__author  + .article-info__date {
                
                &:before {
                    content: '';
                    display: inline-block;
                    width: 0.188rem;
                    height: 0.188rem;
                    margin: 0.188rem 0.625rem 0.188rem 0;
                    background: rgba(52,58,82, 0.5);
                    font-family: Proxima Nova;
                    font-size: 1rem;
                    line-height: 1.25rem;
                }
            }            
            &__share {
                float: right;
                color: rgba(52,58,82, 0.5);

                span, a {
                    display: inline-block;
                    margin: 0 0.25rem;
                }
                span {
                    float: left;
                    line-height: 1.813rem;
                    margin-right: 0.75rem;
                }
            }
        }
        .article-text {
            font-family: Proxima Nova;
            font-size: 1.125rem;
            line-height: 1.875rem;
            color: #343A52;
            
            h2 {
                padding: 1.563rem 5rem 1.563rem;
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 2.125rem;
            }

            h3 {
                padding: 1.563rem 5rem 1.563rem;
                font-size: 1.55rem;
                font-weight: 500;
                line-height: 2.125rem;
            }


            p {
                padding: 0 5rem  1.563rem;
            }
            ul {
                list-style: disc;

                &.empty-list {
                    margin-left: 0;
                    list-style: none;
                }
                &.empty-list2 {
                    //list-style: none;
                    list-style-type: ' - ';
                }

                ul {
                    padding-left: 2rem;
                    padding-bottom: 1rem;
                    list-style-type: circle;
                }
                &.stars {
                    list-style-type: ' ★ ';
                }
            }
            ol {
                list-style: decimal;

                &.lower-alpha {
                    list-style: lower-alpha;
                    li {
                        &::marker {
                            font-weight: bold;
                        }
                    }
                }
            }
            ul,ol {
                padding: 0 5rem  1.563rem;
                list-style-position: outside;
                margin-left: 1.3rem;
            }

            a {
                color: #34C3D3;
                transition: color 0.2s linear;
                
                &:hover {
                    color: #ade8ef;
                }
            }

            a.btn-primary {
                color: #ffffff;
            }

            img {
                margin-bottom: 3.125rem;
                border-radius: 0.625rem;
            }
            img + center {
                margin-top: -3rem;
                margin-bottom: 3.125rem;
                font-size: 1rem;
            }
            .show-mobile {
                display: none;
            }
            .article-text__registration {
                display: flex;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: space-between;
                padding: 3.75rem;
                background: url("../images/content/registration-block-bg.png") no-repeat;
                background-size: cover;
                background-position: center;
                //min-height: 13.688rem;
                margin: 3.438rem -2.5rem 0;
                border-radius: 1.875rem;

                p {
                    padding: 0;
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: 2.25rem;
                    line-height: 3.375rem;
                    color: #ffffff;
                }

            }   
            &__registration-left {
                justify-content: left;
                align-self: center;

            }                
            &__registration-right {
                margin: 0 2.5rem;
                text-align: center;
                align-self: center;

                .btn {
                    white-space: nowrap;
                    padding: 0.813rem 1.688rem 0.875rem;
                }

            }                
        }
     
    }
    .articles {
        padding: 0 2.5rem 7.875rem;

        .article-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -0.813rem;
    
            &__item {
                overflow: hidden;
                flex: 30%;
                margin: 0.813rem;
                background: #E8E8F2;
                border-radius: 1.25rem;
    
                &-text {
                    padding: 1.375rem 1.563rem 1.813rem;
                    font-size: 1.25rem;
                    line-height: 140%;
                    letter-spacing: 0.01em;
                    color: #343A52;
                }
                a .article-list__item-text {
                    transition: color 0.2s linear;
                }
        
                a:hover .article-list__item-text {
                    color: #34c3d3;
                }
            }
    
        }

        &__more {
            padding-bottom: 1.062rem;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 178%;
            color: #343A52;            
        }

    }

}

@media screen and (max-width: 992px) {
        
    .article-page {
        .content {
            .article-text {
                &__registration-right {
                    margin: 0 0 0 1.2rem;
                }
    
            }
        
        }
    }
    .articles {
            
        .article-list {
            justify-content: center;
            &__item {
                flex: 100%;
                max-width: 20.875rem;
            }
        }
    }        

}

@media screen and (max-width: 768px) {
    .solutions-online-retrospective-top-bg {
        background-image: url("../images/content/blog/5-solutions-to-boost-team-engagement-during-your-online-retrospective_tablet.jpg");
    }
    
    .article-page {
        background-size: auto;

        .content {
            &::before {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
            }
                
            &::after {
                width: $border-radius-tablet;
                height: $border-radius-tablet;
                border-bottom-left-radius: $border-radius-tablet;
            }
        }
    }
    .article {
        
        .article-title {
            h1 {
                padding: 0 2.5rem 1.813rem;
                font-size: 1.75rem;
                line-height: 2.813rem;
            }
        }
        
        .container {
            width: auto;
            margin: 0 2.5rem 4.75rem;
            padding: 1.875em 1.563rem 0;
            
            .article-info {
                font-size: 0.875rem;
                margin-bottom: 1.25rem;
    
                &__share {
                    float: right;
                    color: rgba(52,58,82, 0.5);
    
                    span, a {
                        margin: 0 0.188rem;
                    }
                    span {
                        float: left;
                        line-height: 1.813rem;
                        margin-right: 0.438rem;
                    }
                }
            }
            .article-text {
                font-size: 1rem;
                line-height: 1.625rem;
                
                h2 {
                    padding: 1.125rem 0 1.063rem;
                    font-size: 1.5rem;
                    line-height: 1.813rem;
                }
    
                ul,ol,p {
                    padding: 0 0 1.063rem;
                }
    
                img {
                    margin-bottom: 2.188rem;
                }
                img + center {
                    margin-top: -2rem;
                    margin-bottom: 2.188rem;
                    font-size: 0.875rem;
                }
                .article-text__registration {
                    display: block;
                    padding: 1.563rem;
                    margin: 3.438rem -1.563rem 0;

                    .article-text__registration-left {
                        padding-bottom: 0.625rem;
                    }

                    p {
                        text-align: center;
                        font-size: 1.75rem;
                        line-height: 2.813rem;
                    }
                }
        
    
            }
            
        }

        .articles {
            &__more {
                text-align: center;
            }
            .article-list {
                justify-content: center;
                &__item {
                    flex: 100%;
                    max-width: 20.875rem;
                }
                &__item-text {
                    font-size: 1.2rem;
                }
            }
        }        
    }

}
@media screen and (max-width: 576px) {
    .article-page {

        .content {

            &::after {
                display: none;
            }
    
        }
    }
    .article {
    
        .article-title {
            h1 {
                padding: 0 1.25rem 1.875rem;
                font-size: 1.5rem;
                line-height: 2.313rem;
                text-align: left;
            }
        }
        
        .container {
            margin: 0;
            padding: 1.563rem 0 0;
            
            .article-info {
                margin: 0 1.25rem 1.25rem;
    
                &__share {
                    float: none;
                    display: block;
                    overflow: hidden;
                    margin-top: 0.75rem;
    
                    a {
                        float: left;
                    }
                    span {
                        float: none;
                    }
                }
            }
            .article-text {
                line-height: 1.438rem;
                
                h2 {
                    padding: 0.875rem 1.25rem 0.938rem;
                    font-size: 1.375rem;
                    line-height: 1.688rem;
                }
    
                ul,ol,p {
                    padding: 0 1.25rem 1rem;
                }
    
                img {
                    margin-bottom: 1.875rem;
                }
                img + center {
                    margin-top: -1.7rem;
                    margin-bottom: 1.875rem;
                }
                .show-mobile {
                    display: block;
                }
                .article-text__registration {
                    margin: 3.438rem 0 0;
                    border-radius: $border-radius-xs;

                    p {
                        font-size: 1.5rem;
                        line-height: 2.313rem;
                    }
                }

            }
            
        }
        .articles {
            position: relative;
            padding: 1.5rem 1.25rem 1.875rem;
            background: #F7F7FF;
            border-bottom-left-radius: 20px;
            
            .article-list {
                &__item {
                    max-width: none;
                    flex: 100%;
                }
                &__item-text {
                    font-size: 1rem;
                    padding: 1rem 0.938rem 1.563rem 0.938rem;
                }
            }
            &__more {
                font-size: 1.8rem;
                padding-bottom: 0.1rem;
            }
        }
        
    }
 
}

