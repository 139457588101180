@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src:
      url(#{$path}.eot),
      url(#{$path}.eot?#iefix) format("embedded-opentype"),
      url(#{$path}.woff) format("woff"),
      url(#{$path}.woff2) format("woff2");
    font-weight: $weight;
    font-style: $style;
  }
}
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Light', 300, 'normal');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Regular', 400, 'normal');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Semibold', 600, 'normal');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-BoldIt', 700, 'italic');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Bold', 700, 'normal');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Extrabld', 800, 'normal');
@include font-face('Proxima Nova', '../fonts/proxima/proxima/ProximaNova-Black', 900, 'normal');
