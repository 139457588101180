@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap");
@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/sprite";
@include sprites($spritesheet-sprites);

* {
  padding: 0;
  margin: 0;
  font-family: "Proxima Nova", Arial, sans-serif;
}

html {
  font-family: "Proxima Nova", Arial, sans-serif;
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #f7f7ff;
}

body,
html {
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  // max-width: 100%;
  width: 100%;
  display: block;
  height: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

audio,
canvas,
iframe,
img,
video,
textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

a,
input,
select,
button,
label {
  outline: none !important;
}

button,
a {
  text-decoration: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
}

.content {
  flex: 1 0 auto;
  position: relative;
}

.container {
  margin: 0 auto;
  max-width: $content;
  padding: 0 1.25rem;
  width: 100%;
}

.bg-white {
  background-color: #fff;
}

.btn {
  display: block;
  box-sizing: border-box;
  padding: 0.938rem 0.625rem 0.688rem;
  width: 10.938rem;
  height: 2.875rem;
  font-size: 1rem;
  line-height: 1.188rem;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0.313rem;
}

.btn-primary,
.btn-primary:active {
  color: #fff;
  background-color: #fa6767;
}

.btn-primary:hover {
  cursor: pointer;
  background-color: #d04f4f;
}
.btn-inline {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0.625rem 1.375rem 0.688rem;
}

.laptop-visible,
.mobile-visible {
  display: none;
}

.laptop-hidden,
.mobile-hidden {
  display: block;
}

@media screen and (max-width: 991px) {
  .laptop-visible {
    display: block;
  }

  .laptop-hidden {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .mobile-visible {
    display: block;
  }

  .mobile-hidden {
    display: none !important;
  }
}

.text-marker {
  padding-left: 0.188rem;
  padding-right: 0.188rem;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  background: rgba(52, 195, 211, 0.1);
  border-radius: 0.313rem;
}

.text-blue {
  font-size: inherit;
  font-family: inherit;
  color: rgba(52, 195, 211, 1);
}

.cookies-block {
  display: none;
  position: fixed;
  right: 6.25rem;
  bottom: 1.25rem;
  z-index: 1030;
  background: #fff;
  color: rgba(52, 58, 82, 0.8);
  padding: 0.938rem 1.25rem;
  border-radius: 0.625rem;
  transition: box-shadow 0.2s, -webkit-transform 0.18s, transform 0.18s;
  border: 0.063rem solid #dddcdc;
  box-sizing: border-box;
  box-shadow: 0.125rem 0.125rem 0.25rem #dddcdc;

  a {
    color: #34c3d3;
  }
}

.btn-cookies {
  cursor: pointer;
  width: auto;
  height: auto;
  padding-top: 0.438rem;
  margin-left: 0.938rem;
  display: inline-block;
  color: #fff;
  background-color: #34c3d3;
  line-height: 1.063rem;
  height: 2.563rem;
  padding-top: 0.813rem;
}

.main-top-bg {
  position: relative;

  &:after {
    content: "";
    display: block;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background-color: #343a52;
    //    height: 100vh;
    height: calc(100vh - 5.5rem);
    min-height: 39.625rem;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 51%;
    z-index: -1;
  }

  @media screen and (max-width: 1280px) {
    //    &:after {
    //      left: 52%;
    //    }
  }

  @media screen and (max-width: 1080px) {
    &:after {
      left: 58%;
    }
  }

  @media screen and (max-width: 992px) {
    &:after {
      display: none;
    }
  }
}

.pricing-height,
.blog-height {
  min-height: 100vh;

  .content {
    position: relative;
    background-color: #f7f7ff;
    border-bottom-left-radius: $border-radius;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: $border-radius;
      height: $border-radius;
      background: #343a52;
      z-index: -1;
    }

    @media screen and (max-width: 768px) {
      border-bottom-left-radius: $border-radius-tablet;
    }

    @media screen and (max-width: 576px) {
      border-bottom-left-radius: $border-radius-xs;
    }
  }
}

.retro-top-bg {
  background-image: url("../images/content/retro-top-bg.png");
  background-position: left top;
  background-repeat: repeat-x;
}

.telecom {
  background-image: url("../images/content/telecom-banner-bg.svg");
  background-position: left top;
  background-repeat: repeat;
}

.internet {
  background-image: url("../images/content/internet-banner-bg.svg");
  background-position: left top;
  background-repeat: repeat;
}

.main-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 3.125rem;
  line-height: 4rem;
  letter-spacing: 0.02em;
  color: #343a52;
}

.main-banner {
  position: relative;
  overflow: hidden;

  .container {
    //    height: calc(100vh - 8.375rem);
    height: calc(100vh - 13.8rem);
    display: flex;
    min-height: 31.25rem;
  }
  &__mobile-bg {
    display: inline-block;
    margin: auto;
  }

  .text-marker {
    padding-left: 0.313rem;
    padding-right: 0.313rem;
  }

  &__text {
    min-width: 32.188rem;
    padding-bottom: 3rem;
    //    position: relative;
    //    top: 24%;
    margin: auto;
  }

  &__subtitle {
    margin-top: 2.063rem;
    margin-bottom: 3.125rem;
    max-width: 29.25rem;
    font-weight: normal;
    font-size: 1.188rem;
    line-height: 1.938rem;
    letter-spacing: 0.01em;
    color: rgba(52, 58, 82, 0.7);
  }

  &__img {
    position: relative;
    top: -2.875rem;
    right: -13%;
    min-width: 51.375rem;
    z-index: 1;

    img {
      margin-top: 2.3rem;
      width: auto;
      max-width: none;
    }

    .desktop {
      display: block;
    }

    .tablet,
    .mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1280px) {
    &__img {
      //      right: -1.563rem;
      right: -5%;
    }

    //    &:after {
    //      left: 60%;
    //    }
  }

  @media screen and (max-width: 1080px) {
    &__img {
      //      right: 0;
      right: -6%;
    }

    .main-title {
      font-size: 3rem;
      line-height: 3.75rem;
    }

    &__text {
      min-width: 30.813rem;
    }

    //    &:after {
    //      left: 62%;
    //    }
  }

  @media screen and (max-width: 992px) {
    .container {
      flex-direction: column;
      height: auto;
      align-items: center;
    }

    &__mobile-bg {
      position: relative;
      width: calc(100% + 2.5rem);
      margin: 0;

      &:after {
        content: "";
        display: block;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: #343a52;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 7.813rem;
        left: 0;
        z-index: -1;
      }
    }

    &__img {
      top: 3.75rem;
      right: 0;
      position: relative;
      max-height: 40.625rem;
      min-width: auto;
      max-width: 44.063rem;
      margin: auto;
      padding-left: 0.438rem;
      padding-bottom: 3.688rem;

      img {
        // transform: rotate(5deg);
        width: 100%;
        margin-top: -1.5rem;
      }

      .tablet {
        display: block;
      }

      .desktop,
      .mobile {
        display: none;
      }
    }

    .main-title {
      max-width: 42.5rem;
      text-align: center;
    }

    &__text {
      top: 0;
      text-align: center;
      margin-top: 3rem;
      padding-bottom: 0;
    }

    &__subtitle {
      margin: 1.563rem auto 1.813rem;
      font-size: 0.875rem;
      line-height: 1.563rem;
    }

    .btn {
      margin: auto;
    }

    &:after {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .main-banner__text {
      max-width: 22rem;
      min-width: auto;
    }
    .main-title {
      font-size: 2.25rem;
      line-height: 3.375rem;
      text-align: center;
    }

    &__mobile-bg:after {
      border-top-left-radius: $border-radius-tablet;
      border-top-right-radius: $border-radius-tablet;
    }
  }

  @media screen and (max-width: 576px) {
    z-index: 1;
    border-bottom-left-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;

    &__text {
      min-width: auto;
      margin-top: 2rem;
    }

    .main-banner__text {
      max-width: 100%;
    }

    .main-title {
      font-size: 1.875rem;
      line-height: 2.875rem;
      text-align: left;
      max-width: none;
    }
    &__mobile-bg:after {
      top: 4.5rem;
    }

    &__subtitle {
      max-width: none;
      text-align: left;
      margin-top: 0.688rem;
      margin-bottom: 1.875rem;
      margin-right: 0.438rem;
      font-size: 1rem;
      line-height: 1.688rem;
    }

    &__mobile-bg:after {
      border-top-left-radius: $border-radius-xs;
      border-top-right-radius: $border-radius-xs;
    }

    &__img {
      max-width: none;
      top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 0rem;
      margin-left: -20%;
      margin-right: -11%;
    }

    .btn {
      margin-left: 0;
      margin-bottom: 1.188rem;
    }
  }

  @media screen and (max-width: 380px) {
    &__img img {
      width: 100%;
    }
  }
}

.block-demo {
  background: #ededfb;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  .container {
    padding-top: 2.36rem; //changed 0.5x
    padding-bottom: 4.48rem; //changed 0.5x
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
  }

  &__video-block {
    position: relative;
    max-width: 40.688rem;
    width: 52%;
    border-radius: 0.938rem;
    margin-right: 3.125rem;
  }

  &__video {
    border-radius: 0.938rem;
    margin-right: 3.125rem;
    display: none;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    box-shadow: 1.5rem 1.438rem #343a52;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }
  }

  &__img {
    box-shadow: 1.5rem 1.438rem #343a52;
    border-radius: 0.938rem;
    position: relative;
    overflow: hidden;
  }

  .play-video {
    cursor: pointer;
    background: #34c3d3;
    border-radius: 0.625rem;
    padding: 1rem 1.063rem 1.063rem 1.375rem;
    box-shadow: 0.375rem 0.313rem #343a52;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
      background-color: #16a5b5;
    }
  }

  &__text {
    max-width: 28.75rem;
    width: 40%;
    font-size: 1.25rem;
    line-height: 2.188rem;
    letter-spacing: 0.01em;
    color: rgba(52, 58, 82, 0.8);
    margin-right: 2.063rem;
    padding-top: 1.938rem;
  }

  &__title {
    font-weight: bold;
    font-size: 2.188rem;
    line-height: 3.25rem;
    letter-spacing: 0.01em;
    color: rgba(52, 58, 82, 0.7);
    padding-bottom: 1.688rem;

    & + p {
      margin-bottom: 0.813rem;
    }
  }

  @media screen and (max-width: 1129px) {
    &__video-block {
      width: 50%;
    }
  }

  @media screen and (max-width: 992px) and (min-width: 577px) {
    position: relative;

    &::after {
      z-index: -1;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $border-radius;
      height: $border-radius;
      background: #343a52;
    }
  }

  @media screen and (max-width: 1080px) {
    .container {
      align-items: flex-start;
      padding-bottom: 2.36rem;
    }

    &__text {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .container {
      padding-top: 3rem;
      padding-bottom: 2.11rem;
    }

    &__video-block {
      width: 54%;
    }

    &__text {
      width: 46%;
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin-right: 0;
    }

    &__title {
      font-size: 1.875rem;
      line-height: 2.625rem;
      padding-bottom: 0.938rem;

      & + p {
        margin-bottom: 0.625rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-top-left-radius: $border-radius-tablet;
    border-bottom-left-radius: $border-radius-tablet;

    .container {
      justify-content: space-around;
      align-items: flex-start;
    }

    &__video,
    &__img {
      box-shadow: 0.75rem 0.688rem #343a52;
    }

    &__video-block {
      width: 45%;
      margin-right: 1.688rem;
    }

    &__text {
      max-width: 18.125rem;
      width: 100%;
      font-size: 1rem;
      line-height: 1.875rem;
      padding-top: 0;
      margin-top: -1.25rem;
    }

    .play-video {
      padding: 0.688rem 0.688rem 0.625rem 0.938rem;

      img {
        max-width: 0.75rem;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding-bottom: 3rem;
      justify-content: center;
      align-items: baseline;
    }

    &__text {
      max-width: 100%;
      width: 100%;
      margin-bottom: 3.125rem;
    }

    &__video-block {
      width: 100%;
    }

    &__img {
      margin: auto;
      width: 75%;
    }

    &__video {
      margin-right: 3.125rem;
      width: 96%;
    }

    &__title {
      font-size: 1.625rem;
      line-height: 2.375rem;
      padding-bottom: 0.625rem;

      & + p {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 576px) {
    margin-top: -1.25rem;
    padding-top: 1.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: $border-radius-xs;

    &__img {
      width: 97%;
      margin: 0;
    }

    &__video {
      width: 96%;
    }

    &__video-block {
      margin-right: 0;
    }

    &__text {
      margin-bottom: 1.25rem;
    }
  }
}

.block-logos {
  position: relative;
  background: #f7f7ff;
  border-top-right-radius: $border-radius;

  .container {
    padding-top: 3.125rem; //7.125rem;
    padding-bottom: 0;
    overflow-x: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  
  .container.feedback-section {
    padding-top: 1.875rem;
    padding-bottom: 4.375rem;
  }

  .container::-webkit-scrollbar {
    width: 0;
  }

  &__list {
    max-width: 74.813rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 43rem;
    margin: auto;
  }

  &::after {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: $border-radius;
    height: $border-radius;
    background: #ededfb;
  }

  &__img {
    padding: 0 0.813rem;
    min-height: 1.313rem;

    img {
      max-width: none;
    }
  }

  &__img:nth-child(4) {
    margin-top: -1.25rem;
  }

  &__img:nth-child(5) {
    margin-top: 1.25rem;
  }

  &__img:nth-child(6) {
    padding-right: 0;
  }

  @media screen and (max-width: 900px) {
    &__img:nth-child(4) {
      margin-top: -0.875rem;
    }

    &__img:nth-child(5) {
      margin-top: 0.688rem;
    }

    &__img:nth-child(6) {
      margin-top: -0.375rem;
    }
  }

  @media screen and (max-width: 768px) {
    border-top-right-radius: $border-radius-tablet;
  
    &__img {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
    }

    &__img:nth-child(3) {
      margin-top: 0.438rem;
    }

    &__img:nth-child(4) {
      margin-top: -0.688rem;
    }

    &__img:nth-child(5) {
      margin-top: 0.75rem;
    }
  }

  @media screen and (max-width: 576px) {
    border-top-right-radius: $border-radius-xs;

    .container {
      padding-top: 1.688rem;
      padding-bottom: 0;
    }
  
    .container.feedback-section {
      padding-top: 0;
    }

    &__img {
      padding-left: 0.875rem;
      padding-right: 0.875rem;
    }
  }
}

.main-page .block-logos {
  border-bottom-right-radius: $border-radius;
  position: relative;

  &::before {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: $border-radius;
    height: $border-radius;
    background: #343a52;
  }

  @media screen and (max-width: 768px) {
    border-bottom-right-radius: $border-radius-tablet;
  }

  @media screen and (max-width: 576px) {
    border-bottom-right-radius: $border-radius-xs;
  }
}

.middle-banner {
  border-bottom-left-radius: $border-radius;
  background-image: url("../images/content/bg-geometric-dark.png");
  background-size: cover;
  background-position: left -0.875rem bottom;
  padding-top: 2.688rem; //5.875rem
  padding-bottom: 2.688rem; //6.688rem
  position: relative;

  .container {
    text-align: center;
    max-width: 77.188rem;
  }

  &__text {
    font-family: Montserrat;
    padding-bottom: 3.188rem;
    font-weight: 800;
    font-size: 3.75rem;
    line-height: 4.563rem;
    text-align: center;
    color: #f7f7ff;
  }

  .btn {
    margin: auto;
  }

  @media screen and (min-width: 1441px) {
    background-size: contain;
  }

  @media screen and (max-width: 991px) {
    &__text {
      padding-bottom: 3.125rem;
      font-size: 2.75rem;
      line-height: 3.875rem;
    }
  }

  @media screen and (max-width: 768px) {
    border-bottom-left-radius: $border-radius-tablet;

    &__text {
      padding-bottom: 3.125rem;
      font-size: 2.25rem;
      line-height: 3.375rem;
    }
  }

  @media screen and (max-width: 576px) {
    border-bottom-left-radius: $border-radius-xs;

    &__text {
      padding-bottom: 2.125rem;
      font-size: 1.875rem;
      line-height: 2.563rem;
    }
  }
}

.benefits-block {
  padding-top: 2.125rem; //7.125rem;
  padding-bottom: 3.125rem; //7.063rem
  position: relative;

  .text-marker {
    padding-left: 0.188rem;
    padding-right: 0.188rem;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: $border-radius;
    height: $border-radius;
  }

  &::before {
    background: #343a52;
  }

  &::after {
    border-top-right-radius: $border-radius;
    background: #f7f7ff;
  }

  &__title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 2.75rem;
    line-height: 4rem;
    text-align: center;
    color: #343a52;
    margin-bottom: 3.25rem;
  }

  &__list {
    display: grid;
    grid-template-columns: 24.313rem 25.375rem 20.25rem;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 4.563rem;
    grid-row-gap: 3.75rem;
    grid-auto-flow: column;
  }

  &__item:nth-child(3),
  &__item:nth-child(4) {
    margin-left: 0.375rem;
  }

  &__item {
    display: flex;
    flex-wrap: nowrap;
  }

  svg {
    margin-right: 1.5rem;
    overflow: hidden;
    min-width: 5.625rem;
    min-height: 5.625rem;
    width: 5.625rem;
    height: 5.625rem;
    background-color: #fff;
    border-radius: 0.938rem;
    box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 82, 0.15);
  }

  &__subtitle {
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: 0.01em;
    color: #343a52;
    display: inline-block;
    border-radius: 0.313rem;
    margin-top: 0.625rem;
    margin-bottom: 0.188rem;
    padding-left: 0.375rem;
    padding-right: 0.438rem;
  }

  &__description {
    padding-left: 0.375rem;
    font-size: 1rem;
    line-height: 1.313rem;
    color: rgba(52, 58, 82, 0.65);
  }

  @media screen and (max-width: 1310px) {
    &__list {
      grid-column-gap: 2.813rem;
    }
  }

  @media screen and (max-width: 1260px) {
    &__list {
      grid-template-columns: 24.375rem 25.313rem;
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 6.063rem;
      justify-content: center;
    }

    &__item:nth-child(3),
    &__item:nth-child(4) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 950px) {
    &__list {
      grid-column-gap: 2.813rem;
    }
  }

  @media screen and (max-width: 870px) {
    &__list {
      grid-template-columns: 25.313rem;
      grid-template-rows: repeat(6, auto);
      grid-row-gap: 1.563rem;
      margin-left: 0.75rem;
    }

    svg {
      margin-right: 1.438rem;
    }
  }

  @media screen and (max-width: 768px) {
    &__list {
      grid-template-columns: 400px;
      grid-row-gap: 2.5rem;
    }

    &__title {
      font-weight: 800;
      font-size: 1.875rem;
      line-height: 2.813rem;
      margin-bottom: 2.938rem;
    }

    &::before,
    &::after {
      width: $border-radius-tablet;
      height: $border-radius-tablet;
    }

    &::after {
      border-top-right-radius: $border-radius-tablet;
    }
  }

  @media screen and (max-width: 576px) {
    &__list {
      grid-template-columns: 19.75rem;
      grid-row-gap: 2.125rem;
      margin-left: 1.25rem;
    }

    &__title {
      font-weight: bold;
      line-height: 2.625rem;
      margin-bottom: 2.438rem;
    }

    svg {
      width: 3.313rem;
      height: 3.313rem;
      min-width: 3.313rem;
      min-height: 3.313rem;
      margin-right: 0.875rem;
      border-radius: 0.625rem;
    }

    &__subtitle {
      margin-top: 0;
      font-size: 1rem;
    }

    &__description {
      font-size: 0.875rem;
      color: rgba(52, 58, 82, 0.65);
    }

    &::before,
    &::after {
      width: $border-radius-xs;
      height: $border-radius-xs;
    }

    &::after {
      border-top-right-radius: $border-radius-xs;
    }
  }

  @media screen and (max-width: 350px) {
    &__list {
      grid-template-columns: 16.25rem;
    }

    &__subtitle {
      padding: 0.125rem 0.313rem;
      white-space: break-spaces;
      display: inline;
    }
  }
}

.bottom-banner {
  background-color: #f7f7ff;
  padding: 0 1.875rem 3.875rem;
  position: relative;
  border-bottom-right-radius: $border-radius;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: $border-radius;
    height: $border-radius;
    background: #31364a;
    z-index: -1;
  }

  .text-marker {
    background: rgba(255, 255, 255, 0.1);
    padding-left: 0.313rem;
    padding-right: 0.313rem;
  }

  .btn {
    color: #4e57aa;
    background: #ffffff;

    &:hover {
      opacity: 0.9;
    }
  }

  &__container {
    padding: 2.125rem 3.125rem 2.188rem;
    max-width: 1380px;
    margin: auto;
    border-radius: 3.125rem;
    background: url("../images/content/bottom-banner.jpg");
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 4.563rem;
    color: #f7f7ff;
  }

  @media screen and (max-width: 1320px) {
    &__container {
      flex-direction: column;
      justify-content: center;
    }

    &__text {
      text-align: center;
      padding-bottom: 2.188rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 0 2.625rem;
    border-bottom-right-radius: $border-radius-tablet;

    &__text {
      max-width: 31.875rem;
      width: 100%;
      font-size: 1.875rem;
      line-height: 2.813rem;
    }
  }

  @media screen and (max-width: 576px) {
    border-bottom-right-radius: $border-radius-xs;

    &__text {
      line-height: 2.688rem;
      padding-bottom: 2.063rem;
    }
  }
}

.contacts-block {
  background: url("../images/content/contacts-bg.jpg");
  border-top-left-radius: $border-radius;

  .container {
    padding-top: 1.688rem; //3.688rem;
    padding-bottom: 1.688rem; //3.813rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 0.938rem;
    margin-bottom: 0.938rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__item:not(:last-child) {
    margin-right: 0.938rem;
  }

  svg {
    margin-right: 1.563rem;
    width: 3.75rem;
    height: 3.75rem;
  }

  &__label {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.938rem;
  }

  &__data,
  &__data a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.938rem;
  }

  @media screen and (max-width: 1040px) {
    .container {
      justify-content: space-around;
    }

    &__item:last-child {
      margin-top: 2.188rem;
    }
  }

  @media screen and (max-width: 768px) {
    border-top-left-radius: $border-radius-tablet;

    .container {
      width: fit-content;
      flex-direction: column;
      justify-content: center;
    }

    &__item:last-child {
      margin-top: 0.938rem;
    }

    &__label {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    &__data,
    &__data a {
      font-size: 1rem;
      line-height: 1.313rem;
    }
  }

  @media screen and (max-width: 576px) {
    border-top-left-radius: $border-radius-xs;

    svg {
      width: 3.125rem;
      height: 3.125rem;
      margin-right: 0.938rem;
    }
  }
}

.slider-features {
  border-top-left-radius: $border-radius;
  background: #343a52;
  position: relative;

  @media screen and (max-width: 768px) {
    border-top-left-radius: $border-radius-tablet;
  }

  @media screen and (max-width: 576px) {
    border-top-left-radius: 0;
  }

  &__container {
    overflow: hidden;
    padding-top: 2.36rem;
    padding-bottom: 3.172rem;
    position: relative;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $border-radius;
      height: $border-radius;
      background: #f7f7ff;
      z-index: -1;
    }
  }

  &__main {
    max-width: 44.25rem;
  }

  &__navigation-item:hover .slider-features__navigation-item-title {
    color: #34c3d3;
  }

  &__navigation-short-item {
    margin-bottom: 0.188rem;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2.375rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__navigation-item-content {
    display: none;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.438rem;
    color: rgba(255, 255, 255, 0.65);
  }

  &__load {
    overflow: hidden;
    position: relative;
    border-radius: 1.25rem;
    animation-name: fadeUpIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }

  &__navigation {
    flex: 0 0 27.375rem;
    margin-left: 5rem;
    padding-top: 3.125rem;
  }

  &__navigation-item {
    padding: 0.625rem 1.563rem 0.438rem;
  }

  &__navigation-item.active {
    position: relative;
    overflow: hidden;
    padding: 1.25rem 1.563rem 1.875rem;
    margin-bottom: 2rem;
    background: rgba(52, 195, 211, 0.1);
    border-radius: 0.625rem;

    .slider-features__navigation-short-item {
      cursor: auto;
    }

    .slider-features__navigation-item-content {
      display: block;
    }
  }

  &__navigation-item:first-child.active {
    margin-top: 0;
  }

  &__navigation-item-title {
    font-weight: 700;
  }

  .js--loader-block {
    box-shadow: 0.5rem 0.563rem #343a52;
    border-radius: 1.25rem;
  }

  .js--load-item {
    display: none;
  }

  .js--load-item.active {
    display: block;
  }

  &__navigation-item-progress-bar.js-progress-bar {
    width: 0;
    border-radius: 0.625rem;
    background-color: #34c3d3;
    height: 0.125rem;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0.125rem;
  }

  @media screen and (max-width: 1080px) {
    &__navigation {
      margin-left: 2.5rem;
      padding-top: 0;
    }
  }

  @media screen and (max-width: 992px) {
    &__navigation {
      flex: 0 0 21.875rem;
    }

    &__navigation-short-item {
      font-size: 1rem;
      line-height: 2.375rem;
    }

    &__navigation-item-content {
      font-size: 0.875rem;
      line-height: 1.438rem;
      color: rgba(255, 255, 255, 0.65);
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      overflow: visible;
      padding-bottom: 14.5rem;
      flex-direction: column;
    }

    &__main {
      max-width: 43rem;
      margin: auto;
    }

    &__navigation {
      flex-basis: auto;
      width: 100vw;
      margin-left: -1.25rem;
      position: relative;
    }

    &__navigation-items {
      width: 100%;
      margin-top: 1.5rem; //2.5rem;
      display: flex;
      overflow-x: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__navigation-items::-webkit-scrollbar {
      width: 0;
    }

    &__navigation-item {
      flex: 0 0 22.5rem;
      margin: 0;
    }

    &__navigation-item.active {
      margin-left: 1.5rem;
      margin-right: 2.188rem;
    }

    &__navigation-item:first-child {
      margin-left: 2.5rem;
    }

    &__navigation-item,
    &__navigation-item.active {
      padding: 0.813rem 0.813rem 1.188rem 1.563rem;
    }

    &__navigation-short-item {
      margin-bottom: 0;
    }

    &__navigation-item-content {
      font-size: 0.875rem;
      line-height: 1.438rem;
      color: rgba(255, 255, 255, 0.65);
      display: block;
    }
  }

  @media screen and (max-width: 576px) {
    &__container {
      padding-bottom: 17rem;
    }

    &__navigation-short-item {
      line-height: 1.25rem;
      margin-bottom: 0.438rem;
    }

    &__navigation-item {
      flex: 0 0 15.938rem;
    }

    &__navigation-item-content {
      line-height: 1.188rem;
    }

    &__navigation-item:first-child.active {
      margin-left: 1.25rem;
    }

    &__navigation-item.active {
      margin-left: 0.25rem;
      margin-right: 0.938rem;
    }

    &__navigation-item,
    &__navigation-item.active {
      padding: 1.063rem 1.5rem 1.25rem 0.938rem;
    }

    &__navigation-item {
      padding-left: 1.563rem;
    }
  }
}

.feedback-slider {
  max-width: 33rem;
  width: 100%;
  margin: auto;

  .slick-slide {
    position: relative;
    background-image: url("../images/content/feedback-bg.svg");
    background-position: left top;
    background-repeat: no-repeat;
    border-radius: 1.875rem;
    height: 21rem;
    max-width: 33rem;
    width: 100%;
    padding: 5.313rem 0.938rem 1.563rem;
  }

  .first-slide .feedback-slider__user-feedback,
  .third-slide .feedback-slider__user-feedback,
  .fourth-slide .feedback-slider__user-feedback,
  .sixth-slide .feedback-slider__user-feedback,
  .seventh-slide .feedback-slider__user-feedback {
    max-width: 26.75rem;
  }

  .second-slide .feedback-slider__user-feedback,
  .fifth-slide .feedback-slider__user-feedback {
    max-width: 29.5rem;
    font-size: 1rem;
    line-height: 1.563rem;
  }

  .eighth-slide .feedback-slider__user-feedback {
    max-width: 28.75rem;
    font-size: 1rem;
    line-height: 1.563rem;
  }

  &__user-name {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
    color: #ffffff;
  }

  &__user-position {
    font-size: 0.875rem;
    line-height: 1.313;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2.25rem;
  }

  &__user-feedback {
    width: 100%;
    margin: auto;
    font-size: 1.125rem;
    line-height: 1.563rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  &__user-avatar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 6px 5px 0 #34c3d3;
  }

  &.slick-slider .slick-track {
    z-index: 1;
    padding-top: 3.75rem;
  }

  .slick-prev {
    z-index: 2;
    transform: rotate(180deg);
    left: 1.875rem;
    top: 10.188rem;
  }

  .slick-next {
    z-index: 2;
    right: 1.813rem;
    top: 10.813rem;
  }

  .slick-next:before, .slick-prev:before {
    content: "";
    display: block;
    background-image: url("../images/content/arrow-control.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 0.563rem;
    height: 0.938rem;
  }

  .slick-dots {
    bottom: -2.344rem;
  }

  .slick-dots li {
    width: 4.688rem;
    height: 4.688rem;
    position: absolute;
  }

  .slick-dots li button {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    box-shadow: 3px 3px 0 rgba(52, 195, 211, 0.3) ;
  }

  .slick-dots li button img {
    display: block;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-dots li button:before {
    display: block;
    position: relative;
    content: "";
    width: 100%;
    padding-top: 100%;
    height: auto;
  }

  .slick-dots li:nth-child(1) {
    width: 4.063rem;
    height: 4.063rem;
    top: -22.5rem;
    left: -21.188rem;
  }

  .slick-dots li:nth-child(2) {
    width: 3.188rem;
    height: 3.188rem;
    top: -16.438rem;
    left: -12.938rem;
  }

  .slick-dots li:nth-child(3) {
    width: 4.75rem;
    height: 4.75rem;
    top: -12.063rem;
    left: -21.813rem;
  }

  .slick-dots li:nth-child(4) {
    width: 2.25rem;
    height: 2.25rem;
    top: -7.563rem;
    left: -11.25rem;
  }

  .slick-dots li:nth-child(5) {
    width: 4.875rem;
    height: 4.875rem;
    top: -22.5rem;
    right: -15.625rem;
  }

  .slick-dots li:nth-child(6) {
    width: 2.125rem;
    height: 2.125rem;
    top: -16.5rem;
    right: -21.438rem;
  }

  .slick-dots li:nth-child(7) {
    width: 4.438rem;
    height: 4.438rem;
    top: -13.563rem;
    right: -11.688rem;
  }

  .slick-dots li:nth-child(8) {
    width: 3.188rem;
    height: 3.188rem;
    top: -8.063rem;
    right: -18.563rem;
  }

  @media screen and (max-width: 1250px) {
    .slick-dots li:nth-child(1) {
      left: -15.563rem;
    }
  
    .slick-dots li:nth-child(2) {
      left: -7.313rem;
    }
  
    .slick-dots li:nth-child(3) {
      left: -16.188rem;
    }
  
    .slick-dots li:nth-child(4) {
      left: -5.625rem;
    }
  
    .slick-dots li:nth-child(5) {
      right: -10rem;
    }
  
    .slick-dots li:nth-child(6) {
      right: -15.813rem;
    }
  
    .slick-dots li:nth-child(7) {
      right: -6.063rem;
    }
  
    .slick-dots li:nth-child(8) {
      right: -12.938rem;
    }
  }

  @media screen and (max-width: 1067px) {
    .slick-dots li:nth-child(1) {
      left: -8.688rem;
    }
  
    .slick-dots li:nth-child(2) {
      left: -6.063rem;
    }
  
    .slick-dots li:nth-child(3) {
      left: -11.188rem;
    }
  
    .slick-dots li:nth-child(4) {
      left: -4.375rem;
    }
  
    .slick-dots li:nth-child(5) {
      right: -8.75rem;
    }
  
    .slick-dots li:nth-child(6) {
      right: -11.438rem;
    }
  
    .slick-dots li:nth-child(8) {
      right: -8.563rem;
    }
  }

  @media screen and (max-width: 915px) {
    .slick-dots li:nth-child(1) {
      left: -6.813rem;
    }
  
    .slick-dots li:nth-child(2) {
      left: -4.188rem;
    }
  
    .slick-dots li:nth-child(3) {
      left: -8.063rem;
    }
  
    .slick-dots li:nth-child(4) {
      left: -3.125rem;
    }
  
    .slick-dots li:nth-child(5) {
      right: -6.25rem;
    }
  
    .slick-dots li:nth-child(6) {
      right: -8.313rem;
    }
  
    .slick-dots li:nth-child(8) {
      right: -7.313rem;
    }
  }
  
  @media screen and (max-width: 815px) {
    .slick-dots li {
      display: none;
    }
  }
  
  @media screen and (max-width: 575px) {
    max-width: 20.938rem;

    &.slick-slider .slick-track {
      padding-top: 2.438rem;
    }

    .slick-slide {
      max-width: 20.938rem;
      padding-top: 3.438rem;
    }

    &__user-avatar {
      width: 4.688rem;
      height: 4.688rem; 
      box-shadow: 4px 2px 0 #34c3d3;
    }

    &__user-name {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  
    &__user-position {
      font-size: 0.75rem;
      line-height: 1.313rem;
    }

    .second-slide .feedback-slider__user-feedback,
    .fifth-slide .feedback-slider__user-feedback,
    .eighth-slide .feedback-slider__user-feedback,
    &__user-feedback {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .slick-prev {
      left: 0.875rem;
      top: 7.813rem;
    }
  
    .slick-next {
      right: 0.875rem;
      top: 8.438rem;
    }

    &__user-position {
      margin-bottom: 1.375rem;
    }
  
    .slick-next:before, .slick-prev:before {
      background-size: contain;
      width: 0.75rem;
      height: 0.625rem;
    }
  }
}

//Модули
@import "modules/modules";
@import "pages/pages";
