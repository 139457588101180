.footer {
    padding-top: 3.563rem;
    padding-bottom: 3.938rem;
    text-align: center;
    background: #343a52;

    &__logo {
        display: inline-block;
        margin-right: 1.625rem;
        margin-left: 1.625rem;

        max-width: 9.063rem;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__socials__links {
        display: inline-block;

        a {
            margin: 0 0.25rem;
            display: inline-block;
        }
    }

    &__links {
        margin-top: 0.813rem;

        a {
            margin-right: 0.25rem;
            margin-left: 0.188rem;
            display: inline-block;
            padding: 0.625rem;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    &.right-radius {
        border-top-right-radius: $border-radius;

        @media screen and (max-width: 768px) {
            border-top-right-radius: $border-radius-tablet;
        }

        @media screen and (max-width: 576px) {
            border-top-right-radius: $border-radius-xs;
        }
    }
}
